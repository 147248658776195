<template>
  <div class="content-root" v-if="type === 'ongoing'">
    <div class="game-layout">
      <img class="game-cover" :src="cover" />
      <span class="game-title">{{ gameName }}</span>
    </div>
    <div class="user-layout">
      <img class="user-avatar" :src="avatar" />
      <div class="column-layout">
        <div class="row-layout">
          <img class="team-logo" :src="teamLogo" />
          <span class="team-name">{{ teamName }}</span>
        </div>
        <span class="user-name">{{ userName }}</span>
      </div>
    </div>
    <img class="game-qrcode" :src="qrCode" />
  </div>

  <div class="content-root-ended" v-else-if="type === 'ended'">
    <div class="game-layout-ended">
      <img class="game-logo-ended" :src="gameLogo" />
      <span class="game-title-ended">{{ gameName }}</span>
    </div>
    <div class="user-layout-ended">
      <img class="user-avatar" :src="avatar" />
      <div class="column-layout">
        <div class="row-layout">
          <img class="team-logo" :src="teamLogo" />
          <span class="team-name">{{ teamName }}</span>
        </div>
        <span class="user-name">{{ userName }}</span>
      </div>
    </div>
    <img class="game-qrcode-ended" :src="qrCode" />
  </div>
</template>

<script>
import $fetch from "@/utils/fetch.js";

export default {
  name: 'game-stub',
  data() {
    return {
      cover: '',
      gameName: '',
      avatar: '',
      userName: '',
      teamName: '',
      teamLogo: '',
      /**可用参数ended 或者ongoing */
      type: '',
      campaignId: '',
      qrCode: "",
      gameLogo: ''
    }
  },
  created() {
    const query = this.$route.query;
    this.cover = query.cover;
    this.type = query.type;
    this.gameName = query.gameName;
    this.avatar = query.avatar;
    this.userName = query.userName;
    this.teamName = query.teamName;
    this.teamLogo = query.teamLogo;
    this.campaignId = query.campaignId;
    this.gameLogo = query.gameLogo;
    this.getShortCampaignIdQRCode(this.campaignId);
  },
  methods: {
    getShortCampaignIdQRCode(campaignId) {
      $fetch
        .post("EncodeCumenId", { id: campaignId })
        .then((res) => {
          this.getWechatCode(res.id);
        });
    },
    /** 获取活动的小程序码 */
    getWechatCode(shortCampaignId) {
      const _ = this;
      $fetch
        .post("GetWeixinQRCode", {
          page: 'packages/details/pages/index/index',
          scene: shortCampaignId,
          width: 80,
          is_hyaline: true
        })
        .then((code) => {
          _.qrCode = "data:image/jpeg;base64, " + code.image;
        });
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
$PuHuiH: AlibabaPuHuiTiH Alibaba-PuHuiTi-H PingFangSC-Medium;
$multipleCount: 1.146;

.content-root {
  position: relative;
  @include column;
  width: 100vw;
  height: 139.14vw;
  border-radius: 24px;
  background: no-repeat url("https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/01896cbb-8fe9-44b7-6cf1-593dd6911fc3");
  background-size: cover;
}

.game-layout {
  @include row(flex-start, center);
  width: 100%;
  height: px2rem(484 * $multipleCount);
  padding-left: px2rem(32 * $multipleCount);
  padding-top: px2rem(38 * $multipleCount);
  padding-right: px2rem(78 * $multipleCount);
  box-sizing: border-box;

  .game-cover {
    width: px2rem(484 * $multipleCount);
    height: 100%;
    margin-right: px2rem(12 * $multipleCount);
    object-fit: cover;
  }
}

.game-title {
  color: white;
  font-size: px2rem(44 * $multipleCount);
  line-height: px2rem(48 * $multipleCount);
  writing-mode: vertical-lr;
  text-orientation: sideways;
  transform: rotate(-180deg);
  overflow: hidden;
  max-height: 100%;
  align-self: flex-start;
  word-break: break-all;
  width: px2rem(48 * $multipleCount);
  font-family: $PuHuiH;
  font-weight: 900;
}

.column-layout {
  @include column;
  flex-grow: 1;
}

.row-layout {
  @include row(flex-start, center);
  max-width: 100%;
}

.user-layout {
  margin-top: px2rem(40 * $multipleCount);
  margin-left: px2rem(32 * $multipleCount);
  @include row(flex-start, center);
  max-width: calc(100% - px2rem(178 * $multipleCount));
  overflow: hidden;
}

.user-avatar {
  width: px2rem(120 * $multipleCount);
  height: px2rem(120 * $multipleCount);
  border-radius: 50%;
  border-color: #1FFD91;
  border-width: px2rem(2 * $multipleCount);
  border-style: solid;
  margin-right: px2rem(30 * $multipleCount);
  flex-shrink: 0;
  object-fit: cover;
}

.team-logo {
  width: px2rem(48 * $multipleCount);
  height: px2rem(48 * $multipleCount);
  object-fit: cover;
}

.team-name {
  color: white;
  font-family: $PuHuiH;
  font-size: px2rem(24 * $multipleCount);
  line-height: px2rem(36 * $multipleCount);
  text-align: center;
  font-weight: 1000;
  max-width: 100%;
  height: px2rem(36 * $multipleCount);
  margin-left: px2rem(4 * $multipleCount);
  overflow: hidden;
  word-break: break-all;
}

.user-name {
  color: white;
  font-weight: 700;
  font-size: px2rem(63 * $multipleCount);
  line-height: px2rem(64 * $multipleCount);
  margin-top: px2rem(8 * $multipleCount);
  height: px2rem(64 * $multipleCount);
  text-align: center;
  overflow: hidden;
  width: 100%;
  font-family: $PuHuiH;
}

.game-qrcode {
  width: px2rem(110 * $multipleCount);
  height: px2rem(110 * $multipleCount);
  position: absolute;
  right: px2rem(16 * $multipleCount);
  bottom: px2rem(16 * $multipleCount);
  background: white;
  border-radius: px2rem(8 * $multipleCount);
  object-fit: contain;
}

.content-root-ended {
  position: relative;
  @include column(flex-start, center);
  width: 100vw;
  height: 163.91vw;
  border-radius: px2rem(24 * $multipleCount);
  background: no-repeat url("https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/01896cbb-e8e6-31ea-657e-2952ef7d236c");
  background-size: cover;
}

.game-layout-ended {
  @include column(flex-start, center);
  width: px2rem(520 * $multipleCount);

  .game-logo-ended {
    width: px2rem(360 * $multipleCount);
    height: px2rem(360 * $multipleCount);
    object-fit: cover;
  }

  .game-title-ended {
    color: white;
    font-family: $PuHuiH;
    font-size: px2rem(58 * $multipleCount);
    line-height: px2rem(60 * $multipleCount);
    width: 100%;
    max-height: px2rem(120 * $multipleCount);
    text-align: center;
    font-weight: 900;
    margin-top: px2rem(20 * $multipleCount);
    overflow: hidden;
  }
}

.user-layout-ended {
  @include row(flex-start, center);
  margin-top: px2rem(100 * $multipleCount);
  max-width: calc(100% - px2rem(119 * $multipleCount));
  overflow: hidden;
}

.game-qrcode-ended {
  position: absolute;
  width: px2rem(120 * $multipleCount);
  height: px2rem(120 * $multipleCount);
  bottom: px2rem(24 * $multipleCount);
  right: px2rem(24 * $multipleCount);
  background: white;
  border-radius: px2rem(16 * $multipleCount);
  object-fit: contain;
}
</style>
